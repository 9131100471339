html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0; 
    outline: none;
    box-shadow: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

input,button,a{
	outline: none;
	box-shadow: none;
	border: 0;
}

// Font Size
.fs-8{font-size: 8px;}
.fs-9{font-size: 9px;}
.fs-10{font-size: 10px;} 
.fs-11{font-size: 11px;} 
.fs-12{font-size: 12px;} 
.fs-13{font-size: 13px;} 
.fs-14{font-size: 14px;} 
.fs-15{font-size: 15px;} 
.fs-16{font-size: 16px;} 
.fs-17{font-size: 17px;} 
.fs-18{font-size: 18px;} 
.fs-19{font-size: 19px;} 
.fs-20{font-size: 20px;} 
.fs-21{font-size: 21px;} 
.fs-22{font-size: 22px;} 
.fs-23{font-size: 23px;} 
.fs-24{font-size: 24px;} 
.fs-25{font-size: 25px;} 
.fs-26{font-size: 26px;} 
.fs-27{font-size: 27px;} 
.fs-28{font-size: 28px;} 
.fs-29{font-size: 29px;} 
.fs-31{font-size: 31px;} 
.fs-32{font-size: 32px;} 
.fs-33{font-size: 33px;} 
.fs-34{font-size: 34px;} 
.fs-35{font-size: 35px;} 
.fs-36{font-size: 36px;} 
.fs-37{font-size: 37px;} 
.fs-38{font-size: 38px;} 
.fs-39{font-size: 39px;} 
.fs-40{font-size: 40px;}  
.fs-41{font-size: 41px;}  
.fs-42{font-size: 42px;}  
.fs-43{font-size: 43px;}  
.fs-44{font-size: 44px;}  
.fs-45{font-size: 45px;}  
.fs-46{font-size: 46px;}  
.fs-47{font-size: 47px;}  
.fs-48{font-size: 48px;}  
.fs-49{font-size: 49px;}  
.fs-50{font-size: 50px;}  
.fs-51{font-size: 51px;}  
.fs-52{font-size: 52px;}  
.fs-53{font-size: 53px;}  
.fs-54{font-size: 54px;}  
.fs-55{font-size: 55px;}  
.fs-56{font-size: 56px;}  
.fs-57{font-size: 57px;}  
.fs-58{font-size: 58px;}  
.fs-59{font-size: 59px;}  
.fs-60{font-size: 60px;} 

// Padding Top
.pt-5{
	padding-top: 5px;
}
.pt-10{
	padding-top: 10px;
}
.pt-15{
	padding-top: 15px;
}
.pt-20{
	padding-top: 20px;
}
.pt-25{
	padding-top: 25px;
}
.pt-30{
	padding-top: 30px;
}
.pt-35{
	padding-top: 35px;
}
.pt-40{
	padding-top: 40px;
}
.pt-45{
	padding-top: 45px;
}
.pt-50{
	padding-top: 50px;
}
.pt-55{
	padding-top: 55px;
}
.pt-60{
	padding-top: 60px;
}
.pt-65{
	padding-top: 65px;
}
.pt-70{
	padding-top: 70px;
}
.pt-75{
	padding-top: 75px;
}
.pt-80{
	padding-top: 80px;
}
.pt-85{
	padding-top: 85px;
}
.pt-90{
	padding-top: 90px;
}
.pt-95{
	padding-top: 95px;
}
.pt-100{
	padding-top: 100px;
}
.pt-105{
	padding-top: 105px;
}
.pt-110{
	padding-top: 110px;
}
 